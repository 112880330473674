import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["data-test"]
const _hoisted_2 = { class: "flex justify-center" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  class: "svg-to-dark-gray h-[200px] w-[248px] object-contain p-20",
  src: "/images/icons/claritype.svg"
}
const _hoisted_5 = { class: "flex flex-col flex-nowrap justify-between px-2" }
const _hoisted_6 = { class: "w-100 flex flex-row items-center justify-between" }
const _hoisted_7 = { class: "py-[16px] text-lg font-bold" }
const _hoisted_8 = { class: "flex flex-col gap-4" }
const _hoisted_9 = { class: "flex flex-row flex-wrap justify-between text-gray-500" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "flex flex-row flex-wrap justify-between text-gray-500" }
const _hoisted_12 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_close_popper = _resolveDirective("close-popper")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["m-5 w-[290px] rounded-[5px] border-[1px] p-5 hover:border-orange", {
      'border-orange': $setup.module.selected,
      'border-transparent': !$setup.module.selected,
    }])
    }, [
      _createElementVNode("div", {
        onClick: [
          _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($setup.emit('openModule', $setup.module)), ["exact"])),
          _withModifiers($setup.toggleSelected, ["shift","stop"])
        ],
        "data-test": `module-${$setup.module.name}`
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["cursor-pointer rounded-[5px] border-[1px] border-solid border-gray-700", {
          'grayscale-[85%]': !$setup.workspace.current,
        }])
        }, [
          _createElementVNode("div", _hoisted_2, [
            ($setup.module.thumbnail)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  class: "thumbnail h-[200px] w-[248px] object-contain",
                  src: $setup.module.thumbnail
                }, null, 8 /* PROPS */, _hoisted_3))
              : (_openBlock(), _createElementBlock("img", _hoisted_4))
          ])
        ], 2 /* CLASS */)
      ], 8 /* PROPS */, _hoisted_1),
      _createElementVNode("span", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, _toDisplayString($setup.module.name), 1 /* TEXT */),
          ($setup.menuItems.length > 0)
            ? (_openBlock(), _createBlock($setup["Dropdown"], { key: 0 }, {
                popper: _withCtx(() => [
                  _withDirectives(_createVNode($setup["Menu"], { items: $setup.menuItems }, null, 8 /* PROPS */, ["items"]), [
                    [_directive_close_popper]
                  ])
                ]),
                default: _withCtx(() => [
                  _createVNode($setup["Tooltip"], { delay: { show: 1000, hide: 100 } }, {
                    popper: _withCtx(() => [
                      _createTextVNode("Manage " + _toDisplayString($setup.module.name), 1 /* TEXT */)
                    ]),
                    default: _withCtx(() => [
                      _createVNode($setup["IconButton"], {
                        name: "md:more_vert",
                        size: "xl"
                      })
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("span", null, _toDisplayString($setup.workspace.name || "Unknown Workspace"), 1 /* TEXT */),
            ($setup.updatedDate)
              ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString($setup.updatedDate), 1 /* TEXT */))
              : _createCommentVNode("v-if", true)
          ]),
          _createElementVNode("div", _hoisted_11, [
            ($setup.module.owner_name && $setup.showPublishedBy)
              ? (_openBlock(), _createElementBlock("span", _hoisted_12, " Published by " + _toDisplayString($setup.module.owner_name), 1 /* TEXT */))
              : _createCommentVNode("v-if", true)
          ])
        ])
      ])
    ], 2 /* CLASS */),
    ($setup.renamingModule !== undefined)
      ? (_openBlock(), _createBlock($setup["Dialog"], {
          key: 0,
          title: "Rename project",
          "success-label": "Rename",
          onSucceeded: $setup.finishRenameModule,
          onCancelled: _cache[2] || (_cache[2] = ($event: any) => ($setup.renamingModule = undefined))
        }, {
          default: _withCtx(() => [
            _createVNode($setup["Textbox"], {
              label: "Project Name",
              modelValue: $setup.renamingModule,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.renamingModule) = $event)),
              autoselect: true
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    ($setup.duplicateProject !== undefined)
      ? (_openBlock(), _createBlock($setup["Dialog"], {
          key: 1,
          title: "Duplicate project",
          "success-label": "Duplicate",
          onSucceeded: $setup.finishDuplicatingProject,
          onCancelled: _cache[4] || (_cache[4] = ($event: any) => ($setup.duplicateProject = undefined))
        }, {
          default: _withCtx(() => [
            _createVNode($setup["Textbox"], {
              label: "New Project Name",
              modelValue: $setup.duplicateProject,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.duplicateProject) = $event)),
              autoselect: true
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    ($setup.deletingModule)
      ? (_openBlock(), _createBlock($setup["Dialog"], {
          key: 2,
          title: "Delete project?",
          "success-label": "Delete",
          onSucceeded: _cache[5] || (_cache[5] = ($event: any) => ($setup.emit('deleteModule'))),
          onCancelled: _cache[6] || (_cache[6] = ($event: any) => ($setup.deletingModule = false))
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Are you sure you want to delete project " + _toDisplayString($setup.module.name) + "? This cannot be undone. ", 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}