import { Routes, useSettingsStore } from "@/common/stores/settingsStore";
import RedirectPage from "@/home/views/RedirectPage.vue";
import UserHome from "@/home/views/UserHome.vue";

import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Logout from "../views/Logout.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: Routes.Home,
    component: UserHome,
    children: [
      {
        path: Routes.Connections,
        name: Routes.Connections,
        component: UserHome,
      },
      {
        path: Routes.UserSettings,
        name: Routes.UserSettings,
        component: UserHome,
      },
    ],
  },
  {
    path: "/redirect",
    component: RedirectPage,
  },
  {
    path: "/logout",
    component: Logout,
  },
];

const router = createRouter({
  history: createWebHistory("/home"),
  routes,
});

router.beforeEach((to, from, next) => {
  const settingsStore = useSettingsStore();
  if (to.name === Routes.Connections) {
    settingsStore.openConnectionPage();
  } else if (to.name === Routes.UserSettings) {
    settingsStore.openUserSettings();
  } else {
    settingsStore.openHome();
  }
  next();
});
export default router;
