<template>
  <div class="content">Redirecting…</div>
</template>
<style lang="scss" scoped>
.content {
  font-size: 18px;
  margin: $ludicrous-margin;
}
</style>
<script lang="ts" setup>
import { environment } from "@/common/environments/environmentLoader";
import { getCookie } from "@/common/lib/cookie";
import { onMounted } from "vue";

onMounted(() => {
  const defaultUrl = `${location.origin}${environment.require("DEFAULT_LANDING_PAGE")}`;
  const redirectUrl = getCookie("ct_redirect_url");
  document.location.href = redirectUrl || defaultUrl;
});
</script>
