<template>
  <Modal style="background: var(--Action-Bar-Dark)">
    <div class="inner-dialog">
      <div class="header">
        <div class="title">Logged Out</div>
      </div>
      <div class="content">
        <div class="copy">
          <span>You have logged out.</span>
        </div>
      </div>
      <div class="footer">
        <TextButton label="Log back in" @click="goHome()" />
      </div>
    </div>
  </Modal>
</template>

<style lang="scss" scoped>
$databricks-connect-title-font-size: 18px;
$databricks-connect-body-font-size: 14px;
.inner-dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $normal-margin;
}
.header {
  display: flex;
  align-items: center;
  align-self: stretch;
}
.title {
  display: flex;
  padding: $thin-margin 0;
  align-items: center;
  gap: $normal-margin;
  flex: 1 0 0;

  color: var(--Light-Gray);

  font-size: 18px;
  font-weight: 700;
}
.content {
  display: flex;
  padding: $normal-margin 0;
  flex-direction: column;
  align-items: flex-start;
  gap: $normal-margin;
  align-self: stretch;
  max-width: 450px;
  min-width: 450px;
}
.copy {
  display: flex;
  align-items: flex-start;
  gap: $normal-margin;
  align-self: stretch;

  color: var(--White);
  font-size: 14px;
}
.footer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  align-self: stretch;
}
</style>

<script lang="ts" setup>
import Modal from "@/common/components/Modal.vue";
import TextButton from "@/common/components/TextButton.vue";
import { useNavigation } from "@/common/composables/useNavigation";

const navigator = useNavigation();

function goHome() {
  navigator.goHome();
}
</script>
