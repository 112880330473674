// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../node_modules/vue-resize/dist/vue-resize.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "[data-v-8181e0dc]:root {\n  --Action-Bar-Dark: #2c2c2c;\n  --Black: #191919;\n  --Canvas: #222222;\n  --CT-Orange: #f75e0e;\n  --Dark-Gray: #474747;\n  --Light-Gray: #e8e8e8;\n  --Medium-Gray: #8a8a8a;\n  --White: #ffffff;\n}\n.inner-dialog[data-v-8181e0dc] {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 10px;\n}\n.header[data-v-8181e0dc] {\n  display: flex;\n  align-items: center;\n  align-self: stretch;\n}\n.title[data-v-8181e0dc] {\n  display: flex;\n  padding: 5px 0;\n  align-items: center;\n  gap: 10px;\n  flex: 1 0 0;\n  color: var(--Light-Gray);\n  font-size: 18px;\n  font-weight: 700;\n}\n.content[data-v-8181e0dc] {\n  display: flex;\n  padding: 10px 0;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 10px;\n  align-self: stretch;\n  max-width: 450px;\n  min-width: 450px;\n}\n.copy[data-v-8181e0dc] {\n  display: flex;\n  align-items: flex-start;\n  gap: 10px;\n  align-self: stretch;\n  color: var(--White);\n  font-size: 14px;\n}\n.footer[data-v-8181e0dc] {\n  display: flex;\n  justify-content: flex-end;\n  align-items: flex-end;\n  align-self: stretch;\n}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
